import { base } from "../../api/qcfssystem";
import {
  parcels as manifestParcels,
  shipmentApi,
  lineApi,
  lookups 
} from "../../api/manifest";
import dateutil from "../../dateutil";

const ok_beep = new Audio(require("./ok_beep.mp3"));
const error_beep = new Audio(require("./error_beep.mp3"));

// initial state
const state = {
  poolCodeShipToCarriers: null,
  hazmatInfos: null,
  incomingShipments: null,
  facility: "ADC",
  pallets: null,
  currentOrder: null,
  currentOrderParcels: null,
  currentOrderLocked: false,
  currentOrderHazmat: null,
  currentShipment: null,
  currentShipmentOrders: null,
  shipToSummary: null,
  ordersNotBarcoded: null,
  barcodeAssigned: null,
};

// getters
const getters = {
  facility(state) {
    return state.facility;
  },
  poolCodeShipToCarriers(state) {
    return state.poolCodeShipToCarriers;
  },
  hazmatInfos(state) {
    return state.hazmatInfos;
  },
  incomingShipments(state) {
    return state.incomingShipments;
  },
  pallets(state) {
    return state.pallets;
  },
  currentOrder(state) {
    return state.currentOrder;
  },
  currentOrderParcels(state) {
    return state.currentOrderParcels;
  },
  currentOrderHazmat(state) {
    return state.currentOrderHazmat;
  },
  currentOrderLocked(state) {
    return state.currentOrderLocked;
  },
  currentShipment(state) {
    return state.currentShipment;
  },
  currentShipmentOrders(state) {
    return state.currentShipmentOrders;
  },
  shipToSummary(state) {
    return state.shipToSummary;
  },
  ordersNotBarcoded(state) {
    return state.currentShipmentOrders
      .filter((o) => o.parcels.length < o.quantityShipped + o.overShort)
      .sort((a, b) => a.orderNo.localeCompare(b.orderNo));
  },
  currentPallet(state) {
    let p = null;
    if (state.currentOrder) {
      p = state.poolCodeShipToCarriers.find(
        (c) => c.id === state.currentOrder.poolCodeShipToCarrierId,
      );
    }
    return p;
  },
  apiUrl() {
    return base.apiUrl();
  },
  barcodeAssigned(state) {
    return state.barcodeAssigned;
  },
  percentComplete(state) {
    let r = "0%";
    if (state.incomingShipments) {
      let b = 0;
      let p = 0;
      state.incomingShipments.forEach((s) => {
        b += s.parcels;
        p += s.pieces;
      });
      if (p > 0) {
        r = Number(b / p).toLocaleString(undefined, {
          style: "percent",
          minimumFractionDigits: 2,
        });
      }
    }
    return r;
  },
};

// actions
const actions = {
  playOk() {
    ok_beep.play();
  },
  playError() {
    error_beep.play();
  },
  resetStore({ dispatch, commit }) {
    commit("setPoolCodeShipToCarriers", null);
    commit("setIncomingShipments", null);
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", false, { root: true });
  },
  selectOrder({ commit, getters, state }, order) {
    commit("setCurrentOrderHazmat", null);
    if (order) {
      lineApi.getLineHazmat(order.id).then(res => {
        commit("setCurrentOrderHazmat", res.data.payload);
        commit(
          "setCurrentOrder",
          getters.currentShipmentOrders.find((item) => item.id === order.id),
        );
        commit("setCurrentOrderParcels", state.currentOrder.parcels);
      });
    } else {
      commit("setCurrentOrder", null);
      commit("setCurrentOrderParcels", null);
    }
  },
  async generateOverpack({ dispatch }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return manifestParcels
      .generateOverpack()
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async assignLocationToOverpack(
    { dispatch, getters },
    { location, overpack, overpackLink },
  ) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return manifestParcels
      .assignLocationToOverpack(getters.facility, location, overpack)
      .then(() => {
        window.open(overpackLink, "qcfsop");
      })
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async searchOrderSuffix({ state }, suffix) {
    return Promise.resolve(
      state.currentShipmentOrders.filter((o) => o.orderNo.endsWith(suffix)),
    );
  },
  async deleteBarcode({ dispatch, state }, barcode) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return manifestParcels
      .deleteParcel(state.facility, barcode)
      .then(() => {
        ok_beep.play();
      })
      .catch((err) => {
        error_beep.play();
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async selectShipment({ dispatch, commit, getters }, shipment) {
    commit("setCurrentShipment", null);
    commit("setCurrentShipmentOrders", null);
    commit("setCurrentOrder", null);
    commit("setCurrentOrderLocked", false);
    if (shipment) {
      dispatch("common/setErrorMessage", null, { root: true });
      dispatch("common/setLoading", true, { root: true });
      let parcelLoader = manifestParcels.loadParcelsForShipment(shipment.id);
      let orderLoader = shipmentApi
        .loadShipmentLines(shipment.id)
        .then((res) => {
          commit("setCurrentShipmentOrders", res.data.payload);
        });
      return Promise.all([parcelLoader, orderLoader])
        .then((values) => {
          let shipToSummaryTmp = [];
          state.currentShipmentOrders.forEach((o) => {
            let shipToEntry = shipToSummaryTmp.find(
              (e) => e.shipToCode === o.shipToCode,
            );
            if (!shipToEntry) {
              shipToEntry = {
                shipToCode: o.shipToCode,
                barcoded: 0,
                pieces: 0,
              };
              shipToSummaryTmp.push(shipToEntry);
            }
            shipToEntry.pieces =
              shipToEntry.pieces + o.quantityShipped + o.overShort;
          });
          shipToSummaryTmp.sort((a, b) =>
            a.shipToCode && b.shipToCode
              ? a.shipToCode.localeCompare(b.shipToCode)
              : 0,
          );
          commit("setShipToSummary", shipToSummaryTmp);
          getters.currentShipmentOrders.forEach((o) => {
            o.parcels = [];
            values[0].data.payload
              .filter((p) => p.incomingShipmentId === o.id)
              .forEach((p) => commit("addParcelToOrder", p));
          });
          commit("setCurrentShipment", shipment);
        })
        .catch((err) => {
          dispatch("common/handleHttpError", err, {
            root: true,
          });
        })
        .finally(() => {
          dispatch("common/setLoading", false, { root: true });
        });
    }
  },
  toggleOrderLocked({ commit, getters }) {
    let val = getters.currentOrderLocked == true ? false : true;
    commit("setCurrentOrderLocked", val);
  },
  async assignBarcodeToOrder(
    { dispatch, getters, commit },
    { barcode, damaged, hmCodes },
  ) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    commit("setBarcodeAssigned", barcode);
    manifestParcels
      .assignBarcodeToLine(
        getters.facility,
        barcode,
        getters.currentOrder.id,
        getters.currentPallet.defaultJdmLoc,
        null,
        damaged,
        hmCodes
      )
      .then((res) => {
        commit("addParcelToOrder", res.data.payload);
        ok_beep.play();
        dispatch("selectOrder", null);
      })
      .catch((err) => {
        error_beep.play();
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
        commit("setBarcodeAssigned", null);
      });
  },
  async handleParcelCodeUpdateAdded({ commit, getters }, parcelCodeUpdate) {
    commit(
      "incrementIncomingShipmentPiecesBarcoded",
      parcelCodeUpdate.incomingShipment.incomingShipmentHeaderId,
    );
    commit("addToPallet", parcelCodeUpdate.parcelCode);
    if (
      getters.currentShipment &&
      getters.currentShipment.id ===
        parcelCodeUpdate.incomingShipment.incomingShipmentHeaderId
    ) {
      commit("addParcelToOrder", parcelCodeUpdate.parcelCode);
    }
  },
  async handleParcelCodeUpdateDeleted({ commit, getters }, parcelCodeUpdate) {
    commit(
      "decrementIncomingShipmentPiecesBarcoded",
      parcelCodeUpdate.incomingShipment.incomingShipmentHeaderId,
    );
    commit("removeFromPallet", parcelCodeUpdate.parcelCode);
    if (
      getters.currentShipment &&
      getters.currentShipment.id ===
        parcelCodeUpdate.incomingShipment.incomingShipmentHeaderId
    ) {
      commit("removeParcelFromOrder", parcelCodeUpdate.parcelCode);
    }
  },
  async handleParcelCodeUpdateAssignedLocation({ commit }, parcelCodeUpdate) {
    commit("addToPallet", parcelCodeUpdate.parcelCode);
  },
  async handleParcelCodeUpdateRemovedFromLocation(
    { commit },
    parcelCodeUpdate,
  ) {
    commit("removeFromPallet", parcelCodeUpdate.parcelCode);
  },
  async handleParcelCodeUpdateAssignedLocationToExternal(
    { commit },
    parcelCodeUpdate,
  ) {
    commit("removeFromPallet", parcelCodeUpdate.parcelCode);
  },
  async handleParcelCodeMutationAssignedOverpack({ commit }, parcelCode) {
    commit("removeFromPallet", parcelCode);
  },
  async handleParcelCodeMutationParcelDeleted({ commit }, parcelCode) {
    if (parcelCode.incomingShipmentId) {
      let line = await lineApi
        .loadLine(parcelCode.incomingShipmentId)
        .then((res) => res.data.payload);
      if (line) {
        commit(
          "decrementIncomingShipmentPiecesBarcoded",
          line.incomingShipmentHeaderId,
        );
        commit("removeFromPallet", parcelCode);
        if (
          getters.currentShipment &&
          getters.currentShipment.id === line.incomingShipmentHeaderId
        ) {
          commit("removeParcelFromOrder", parcelCode);
        }
      }
    }
  },
  async handleParcelCodeMutationParcelAdded({ commit, getters }, parcelCode) {
    if (parcelCode.incomingShipmentId) {
      let line = await lineApi
        .loadLine(parcelCode.incomingShipmentId)
        .then((res) => res.data.payload);
      if (line) {
        commit(
          "incrementIncomingShipmentPiecesBarcoded",
          line.incomingShipmentHeaderId,
        );
        commit("addToPallet", parcelCode);
        if (
          getters.currentShipment &&
          getters.currentShipment.id === line.incomingShipmentHeaderId
        ) {
          commit("addParcelToOrder", parcelCode);
        }
      }
    }
  },
  handleParcelCodeUpdate({ dispatch }, parcelCodeUpdateStr) {
    let parcelCodeUpdate = JSON.parse(parcelCodeUpdateStr);
    //console.log(parcelCodeUpdate);
    if (parcelCodeUpdate.error) {
      // NOOP
      //console.log("parcelCodeUpdate ERROR");
    } else {
      switch (parcelCodeUpdate.action) {
        case "Added":
          dispatch("handleParcelCodeUpdateAdded", parcelCodeUpdate);
          break;
        case "Deleted":
          dispatch("handleParcelCodeUpdateDeleted", parcelCodeUpdate);
          break;
        case "AssignedLocationToExternal":
          dispatch(
            "handleParcelCodeUpdateAssignedLocationToExternal",
            parcelCodeUpdate,
          );
          break;
        case "AssignedLocation":
          dispatch("handleParcelCodeUpdateAssignedLocation", parcelCodeUpdate);
          break;
        case "RemoveFromLocation":
          dispatch(
            "handleParcelCodeUpdateRemovedFromLocation",
            parcelCodeUpdate,
          );
          break;
        default:
          break;
      }
    }
  },
  handleParcelCodeMutation({ dispatch }, parcelCodeMutationStr) {
    let parcelCodeMutation = JSON.parse(parcelCodeMutationStr);
    //console.log(parcelCodeMutation);
    switch (parcelCodeMutation.action) {
      case "ParcelAdded":
        dispatch(
          "handleParcelCodeMutationParcelAdded",
          parcelCodeMutation.current,
        );
        break;
      case "ParcelDeleted":
        dispatch(
          "handleParcelCodeMutationParcelDeleted",
          parcelCodeMutation.previous,
        );
        break;
      case "ParcelAssignedLocation":
        break;
      case "ParcelAssignedOverpack":
        dispatch(
          "handleParcelCodeMutationAssignedOverpack",
          parcelCodeMutation.previous,
        );
        break;
      case "ParcelRemovedFromLocation":
        break;
      default:
        break;
    }
  },
  async initialize({ dispatch, commit, getters }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    let promises = [];
    const exludeRegex = ["T1", "R1", "E1"];
    let promPoolCodes = lookups.loadPoolCodeShipToCarriers().then((res) => {
      commit("setPoolCodeShipToCarriers", res.data.payload);
      let tmpPallets = [];
      res.data.payload.forEach((element) => {
        if (
          element.defaultJdmLoc &&
          exludeRegex.indexOf(element.poolCodeRegex) < 0
        ) {
          let tmpPallet = {
            id: element.id,
            name: element.defaultJdmLoc,
            contents: [],
          };
          tmpPallets.push(tmpPallet);
          let promLoadParcels = manifestParcels
            .loadParcelsForLocation(getters.facility, element.defaultJdmLoc)
            .then((res) => {
              tmpPallet.contents = res.data.payload;
            });
          promises.push(promLoadParcels);
        }
      });
      tmpPallets.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      commit("setPallets", tmpPallets);
    });
    let promHzLookup = lookups.loadHazmatInfo().then(res => {
      commit("setHazmatInfo", res.data.payload);
    });
    promises.push(promPoolCodes);
    promises.push(promHzLookup);
    let now = dateutil.utcNow();
    let cutoff = dateutil.getLocalDateTime(
      now.subtract(60, "days"),
      "YYYY-MM-DD",
    );
    let promShipments = shipmentApi
      .loadOpenShipmentSummaries("ADC", cutoff)
      .then((res) => {
        let tmpShipments = res.data.payload.filter((val) => {
          return val.shipmentTypeCode === 1;
        });
        tmpShipments.forEach((s) => {
          s.dateShipped = dateutil.getLocalDateTime(
            s.dateShipped,
            "YYYY-MM-DD",
          );
        });
        tmpShipments.sort((a, b) => {
          return a.shipmentName.localeCompare(b.shipmentName);
        });
        commit("setIncomingShipments", tmpShipments);
      });
    promises.push(promShipments);
    return Promise.all(promises)
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
};

// mutations
const mutations = {
  setHazmatInfo(state, obj) {
    state.hazmatInfos = obj;
  },
  setCurrentOrderHazmat(state, obj) {
    state.currentOrderHazmat = obj;
  },
  setBarcodeAssigned(state, obj) {
    state.barcodeAssigned = obj;
  },
  setPoolCodeShipToCarriers(state, obj) {
    state.poolCodeShipToCarriers = obj;
  },
  setIncomingShipments(state, obj) {
    state.incomingShipments = obj;
  },
  setPallets(state, obj) {
    state.pallets = obj;
  },
  incrementIncomingShipmentPiecesBarcoded(state, id) {
    let result = state.incomingShipments.find((x) => {
      return x.id === id;
    });
    if (result) {
      result.parcels = result.parcels + 1;
    }
  },
  decrementIncomingShipmentPiecesBarcoded(state, id) {
    let result = state.incomingShipments.find((x) => {
      return x.id === id;
    });
    if (result) {
      result.parcels = result.parcels - 1;
    }
  },
  addToPallet(state, parcelCode) {
    let result = state.pallets.find((x) => x.name === parcelCode.location);
    if (result) {
      result.contents.push(parcelCode);
    }
  },
  removeFromPallet(state, parcelCode) {
    let pallet = state.pallets.find(
      (item) => item.name === parcelCode.location,
    );
    if (!pallet) {
      state.pallets.forEach((p) => {
        if (
          p.contents
            .map((item) => item.facility + ":" + item.externalId)
            .indexOf(parcelCode.facility + ":" + parcelCode.externalId) > -1
        ) {
          pallet = p;
        }
      });
    }
    if (pallet) {
      let idx = pallet.contents
        .map((item) => item.facility + ":" + item.externalId)
        .indexOf(parcelCode.facility + ":" + parcelCode.externalId);
      if (idx > -1) {
        pallet.contents.splice(idx, 1);
      }
    }
  },
  setCurrentShipment(state, shipment) {
    state.currentShipment = shipment;
  },
  setCurrentShipmentOrders(state, orders) {
    state.currentShipmentOrders = orders;
  },
  updateParcelLocation(state, parcel) {
    let order = state.currentShipmentOrders.find(
      (item) => item.id === parcel.incomingShipmentId,
    );
    if (order) {
      if (!order.parcels) {
        order.parcels = [];
      }
      if (
        !order.parcels.find(
          (item) =>
            item.facility === parcel.facility &&
            item.externalId === parcel.externalId,
        )
      ) {
        order.parcels.push(parcel);
        let sss = state.shipToSummary.find(
          (item) => item.shipToCode === order.shipToCode,
        );
        if (sss) {
          sss.barcoded = sss.barcoded + 1;
        }
      }
    }
  },
  addParcelToOrder(state, parcel) {
    let order = state.currentShipmentOrders.find(
      (item) => item.id === parcel.incomingShipmentId,
    );
    if (order) {
      if (!order.parcels) {
        order.parcels = [];
      }
      if (
        !order.parcels.find(
          (item) =>
            item.facility === parcel.facility &&
            item.externalId === parcel.externalId,
        )
      ) {
        order.parcels.push(parcel);
        let sss = state.shipToSummary.find(
          (item) => item.shipToCode === order.shipToCode,
        );
        if (sss) {
          sss.barcoded = sss.barcoded + 1;
        }
      }
    }
  },
  removeParcelFromOrder(state, parcel) {
    let order = state.currentShipmentOrders.find(
      (item) => item.id === parcel.incomingShipmentId,
    );
    if (order) {
      let idx = order.parcels
        .map((item) => item.facility + ":" + item.externalId)
        .indexOf(parcel.facility + ":" + parcel.externalId);
      if (idx > -1) {
        order.parcels.splice(idx, 1);
        let sss = state.shipToSummary.find(
          (item) => item.shipToCode === order.shipToCode,
        );
        if (sss) {
          sss.barcoded = sss.barcoded - 1;
        }
      }
    }
  },
  setShipToSummary(state, summary) {
    state.shipToSummary = summary;
  },
  setCurrentOrder(state, order) {
    state.currentOrder = order;
  },
  setCurrentOrderParcels(state, parcels) {
    state.currentOrderParcels = parcels;
  },
  setCurrentOrderLocked(state, locked) {
    state.currentOrderLocked = locked;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
