import axios from "axios";
import store from "../store";
import textutils from "../textutils";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = MANIFEST_API_URL;
} catch (e) {
  API_URL = "http://manifestapi.home";
  //API_URL = "http://localhost:9090";
}

const lookups = {
  async loadPoolCodeShipToCarriers() {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/lookups/poolcodeshiptocarriers";
    return axios.get(url);
  },
  async loadShipmentTypes() {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/lookups/shipmenttypes";
    return axios.get(url);
  },
  async loadLineTypes() {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/lookups/manifesttypes";
    return axios.get(url);
  },
  async loadHazmatInfo() {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/lookups/hazmatinfo";
    return axios.get(url);
  }
};

const rating = {
  async getUnratedManifests() {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/manifest/unrated";
    return axios.get(url);
  },
  async rateManifest(manifestNo, counter, count) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${manifestNo}/rate?counter=${counter}&count=${count}&by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async setFuelRate(dateStr, fuelRate) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/fuelrate/${dateStr}/${fuelRate}?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
};

const serial = {
  async create(serialNo, orderNo) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/serialonorder/${serialNo}/${orderNo}?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async delete(serialNo) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/serialonorder/${serialNo}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async findByOrder(orderNo) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/serialonorder/find/order/${orderNo}`;
    return axios.get(url);
  },
  async findBySerial(serialNo) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/serialonorder/find/serial/${serialNo}`;
    return axios.get(url);
  },
};

const parcels = {
  async assignLocationToOverpack(facility, location, overpack) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/location/${location}/overpack/${overpack}?facility=${facility}&by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async assignParcelToOverpack(facility, barcode, overpack) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/${facility}/${barcode}/overpack?overpack=${overpack}&by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async assignParcelToLocation(facility, barcode, location, damaged) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/parcel/${facility}/${barcode}/location?location=${location}&by=${store.getters["security/userName"]}`;
    if (damaged != null) {
      url = `${url}&damaged=${damaged}`;
    }
    return axios.post(url);
  },
  async assignParcelToBol(facility, barcode, bol) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/${facility}/${barcode}/bol?bol=${bol}&by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async assignBarcodeToLine(
    facility,
    barcode,
    lineId,
    location,
    manifestNo,
    damaged,
    hmCodes
  ) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/parcel/${facility}/${barcode}/line?lineId=${lineId}&damaged=${damaged}&by=${store.getters["security/userName"]}`;
    if (location != null) {
      url = `${url}&location=${location}`;
    }
    if (manifestNo != null) {
      url = `${url}&manifestNo=${manifestNo}`;
    }
    if (hmCodes != null && hmCodes.length > 0) {
      url = `${url}&hmCodes=${hmCodes}`;
    }
    console.log(`${url}`);
    return axios.post(url);
  },
  async generateOverpack() {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/generate/QCOP/1?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async loadParcelsForLocation(facility, location) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/all/location/${location}?facility=${facility}`;
    return axios.get(url);
  },
  async loadParcelsForShipment(shipmentId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/all/incomingshipmentheader/${shipmentId}`;
    return axios.get(url);
  },
  async searchParcels(facility, barcode) {
    await store.dispatch("security/refreshSecurity");
    const url = new URL(`${API_URL}/parcel`);
    url.searchParams.append("externalId", textutils.blankIfNull(barcode));
    url.searchParams.append("facility", textutils.blankIfNull(facility));
    return axios.get(url);
  },
  async deleteParcel(facility, barcode) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/${facility}/${barcode}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async removeParcelFromManifest(facility, barcode) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/${facility}/${barcode}/manifest?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async removeParcelFromLocation(facility, barcode) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/${facility}/${barcode}/location?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async removeParcelFromOverpack(facility, barcode) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/parcel/${facility}/${barcode}/overpack?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
};

const bolApi = {
  async loadBol(bolNo) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bol/${bolNo}`;
    return axios.get(url);
  },
  async loadStopsForBol(bolNo) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bol/${bolNo}/stops`;
    return axios.get(url);
  },
  async loadOpenBols(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/bol/open?facility=${facility}`;
    return axios.get(url);
  },
  async createBol(bol, stopCode, manifestNo) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/bol?by=${store.getters["security/userName"]}`;
    if (stopCode) {
      url = `${url}&stopCode=${stopCode}`;
    }
    if (manifestNo) {
      url = `${url}&manifestNo=${manifestNo}`;
    }
    return axios.post(url, bol, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async updateBol(bol) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/bol/${bol.billOfLadingNo}?by=${store.getters["security/userName"]}`;
    return axios.put(url, bol, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async deleteBol(bol) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/bol/${bol.billOfLadingNo}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async finalizeBol(bol) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/bol/${bol.billOfLadingNo}/finalize?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
};

const stopApi = {
  async loadManifests(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/stop/${id}/manifests`;
    return axios.get(url);
  },
  async createStop(stop, manifestNo) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/stop?by=${store.getters["security/userName"]}`;
    if (manifestNo) {
      url = `${url}&manifestNo=${manifestNo}`;
    }
    return axios.post(url, stop, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async deleteStop(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/stop/${id}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async updateStop(stop) {
    await store.dispatch("security/refreshSecurity");
    let url = `${API_URL}/stop/${stop.id}?by=${store.getters["security/userName"]}`;
    return axios.put(url, stop, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async moveStopDown(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/stop/${id}/movedown?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async moveStopUp(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/stop/${id}/moveup?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async getPieceCount(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/stop/${id}/pieces`;
    return axios.get(url);
  },
  async getWeight(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/stop/${id}/weight`;
    return axios.get(url);
  },
};

const manifestApi = {
  async loadPieces(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${id}/pieces`;
    return axios.get(url);
  },
  async loadWeight(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${id}/weight`;
    return axios.get(url);
  },
  async loadLineCount(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${id}/linecount`;
    return axios.get(url);
  },
  async loadIsHazmat(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${id}/ishazmat`;
    return axios.get(url);
  },
  async loadShipments(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${id}/shipments`;
    return axios.get(url);
  },
  async loadOpenManifests(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/open?facility=${facility}`;
    return axios.get(url);
  },
  async loadIsCanadian(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${id}/iscanadian`;
    return axios.get(url);
  },
  async removeFromStop(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${id}/stop?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async assignToStop(manifestNo, stopId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/manifest/${manifestNo}/stop?stopId=${stopId}&by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
};

const partyApi = {
  async loadParty(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/party?id=${id}`;
    return axios.get(url);
  },
};

const shipmentApi = {
  async loadOpenShipmentSummaries(facility, cutoff) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/opensummaries?&cutoff=${cutoff}&facility=${facility}`;
    return axios.get(url);
  },
  async loadShipmentSummary(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/${id}/summary`;
    return axios.get(url);
  },
  async loadShipment(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/${id}`;
    return axios.get(url);
  },
  async loadShipmentLines(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/${id}/lines`;
    return axios.get(url);
  },
  async loadShipmentParcels(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/${id}/parcels`;
    return axios.get(url);
  },
  async acceptShipment(shipmentId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/${shipmentId}/accept?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async deleteShipment(shipmentId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/${shipmentId}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async createShipmentFromManifest(manifestNo, shipmentTypeId, facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/createfrommanifest?by=${store.getters["security/userName"]}&manifestNo=${manifestNo}&facility=${facility}&shipmentTypeId=${shipmentTypeId}`;
    return axios.post(url);
  },
  async createShipment(shipment) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment?by=${store.getters["security/userName"]}`;
    return axios.post(url, shipment, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async createBarcodesForShipment(shipmentId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/${shipmentId}/parcels/generate?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async splitAllByShipTo(shipmentId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/shipment/${shipmentId}/splitallbyshipto?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
};

const lineApi = {
  async loadLine(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/line/${id}`;
    return axios.get(url);
  },
  async saveLine(line) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/line/${line.id}?by=${store.getters["security/userName"]}`;
    return axios.put(url, line, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async createLine(line) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/line?by=${store.getters["security/userName"]}`;
    return axios.post(url, line, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async deleteLine(lineId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/line/${lineId}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async getLineHazmat(lineId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/line/${lineId}/hazmat`;
    return axios.get(url);
  }
};

const globalApi = {
  async getNotificationMessage() {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/global/notificationmessage`;
    return axios.get(url);
  },
  async setNotificationMessage(level, message) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/global/notificationmessage?level=${level}&message=${message}`;
    return axios.post(url);
  },
};

export {
  lookups,
  rating,
  serial,
  parcels,
  bolApi,
  stopApi,
  manifestApi,
  partyApi,
  shipmentApi,
  lineApi,
  globalApi,
};
