<template>
  <div id="orderPanel">
    <div class="row">
      <div class="col-8">
        <div class="container-fluid">
          <div class="row">
            <div class="col-4" @click="selectOrder(null)" style="text-decoration: underline">
              Order
            </div>
            <!--<div class="col-1"><label for="toggleOrderLocked" class="fa fa-lock"/></div>
                        <div class="col-1"><input @click="toggleOrderLocked" id="toggleOrderLocked" class="align-middle form-control form-control-lg" type="checkbox" :value="currentOrderLocked"/></div>-->
            <div class="col-8">{{ currentOrder.orderNo }}</div>
          </div>
          <div class="row">
            <div class="col-4">Name</div>
            <div class="col-8">{{ currentOrder.destName }}</div>
          </div>
          <div class="row">
            <div class="col-4">Address</div>
            <div class="col-8">{{ currentOrder.destAddress1 }}</div>
          </div>
          <div class="row">
            <div class="col-4">City</div>
            <div class="col-8">{{ currentOrder.destCity }}</div>
          </div>
          <div class="row">
            <div class="col-4">State</div>
            <div class="col-8">{{ currentOrder.destState }}</div>
          </div>
          <div class="row">
            <div class="col-4">Pool Code</div>
            <div class="col-8">{{ currentOrder.poolCode }}</div>
          </div>
          <div class="row">
            <div class="col-4">Damaged</div>
            <div class="col-1">
              <input @click="toggleDamaged" id="toggleDamaged" class="align-middle form-control form-control-lg"
                type="checkbox" :value="damaged" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div style="border: 1px solid black; margin-right: 15px" class="col text-center">
          Pallet
          <p class="h-100 align-middle" style="font-size: 500%">
            {{ palletLabel }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="container-fluid">
          <div v-if="currentOrderHazmat">
            <div class="row">
              <div class="col-12">
                <hr />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                Hazmat
              </div>
              <div class="col-9" style="font-size:75%">
                <div v-for="hz in currentOrderHazmat" v-bind:key="hz.id" class="row">
                  <div class="col-1">
                    <input class="align-middle form-control form-control-md"
                    type="checkbox" v-model="hmCodes" :value="hz.hmCode" />
                  </div>
                  <div class="col-11">
                    {{ hazmatDescription(hz) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        {{ currentOrderParcels.length }} of
        {{ currentOrder.quantityShipped + currentOrder.overShort }} pieces
        assigned.
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        Barcode
        <input type="text" ref="focusMe" id="barcodeToAssign" v-model.trim="barcodeToAssign" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrderPanel",
  data() {
    return {
      barcodeToAssign: null,
      damaged: false,
      hmCodes: [],
    };
  },
  watch: {
    barcodeToAssign(newVal) {
      if (newVal && newVal.length > 3) {
        if (!newVal.startsWith("QCFS")) {
          this.setErrorMessage("Barcode must start with QCFS");
          this.barcodeToAssign = null;
        } else {
          if (newVal.length === 16) {
            this.assignBarcodeToOrder({
              barcode: newVal,
              damaged: this.damaged,
              hmCodes: this.hmCodes.join()
            }).finally(() => {
              this.barcodeToAssign = null;
              this.hmCodes = [];
            });
          }
        }
      }
    },
    barcodeAssigned(newVal) {
      if (!newVal) {
        this.autoFocus();
      }
    },
  },
  computed: {
    ...mapGetters("jdm", [
      "currentOrder",
      "currentOrderHazmat",
      "currentOrderLocked",
      "currentPallet",
      "currentOrderParcels",
      "barcodeAssigned",
      "hazmatInfos",
    ]),
    palletLabel: function () {
      let r = null;
      if (this.currentPallet && this.currentPallet.defaultJdmLoc) {
        let suffix = this.currentPallet.defaultJdmLoc.slice(
          this.currentPallet.defaultJdmLoc.length - 3,
        );
        if (!isNaN(suffix)) {
          r = new Number(suffix);
        }
      }
      return r;
    },
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("jdm", [
      //"toggleOrderLocked",
      "assignBarcodeToOrder",
      "selectOrder",
    ]),
    autoFocus: function () {
      if (this.$refs.focusMe) {
        this.$refs.focusMe.focus();
      }
    },
    toggleDamaged() {
      this.damaged = !this.damaged;
    },
    hazmatDescription(hz) {
      let hzInfo = this.hazmatInfos.find(i => i.hmCode == hz.hmCode);
      return hzInfo ? `${hzInfo.unNumber}, ${hzInfo.properShippingName}, ${hzInfo.hazardClassDivision}, ${hzInfo.packingGroup}, ${hzInfo.erg}, ${hzInfo.specialPermits}` : "";
    }
  },
  mounted() {
    this.autoFocus();
  },
};
</script>

<style scoped>
#orderPanel {
  font-size: 100%;
}
</style>
