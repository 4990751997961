<template>
  <b-container id="mainContainer" fluid class="d-md-flex flex-column h-100">
    <b-navbar toggleable="md" variant="dark" type="dark">
      <b-navbar-brand :to="{ name: 'homepage' }">QCFS MISSY</b-navbar-brand>
      <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>
      <b-collapse id="navbarSupportedContent" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown
            v-show="
              inRole('Yard Spot') ||
              inRole('Dispatch') ||
              inRole('Yard Security') ||
              inRole('Trailer Board') ||
              inRole('Drivers') ||
              inRole('Yard Exec Summary')
            "
            text="Dispatch"
          >
            <b-dropdown-item
              v-if="inRole('Trailer Board')"
              :to="{ name: 'trailers' }"
              >Trailer Board</b-dropdown-item
            >
            <b-dropdown-item
              v-if="inRole('Trailer Board')"
              :to="{ name: 'addtrailer' }"
              >Add Trailer</b-dropdown-item
            >
            <b-dropdown-item
              v-if="inRole('Trailer Board')"
              :to="{ name: 'addfacilitylocation' }"
              >Add Facility Location</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item
              v-if="
                inRole('Yard Spot') ||
                inRole('Dispatch') ||
                inRole('Yard Security')
              "
              :to="{ name: 'yard' }"
              >Yard Board</b-dropdown-item
            >
            <b-dropdown-item
              v-if="inRole('Dispatch') || inRole('Yard Exec Summary')"
              :to="{ name: 'yardexec' }"
              >Yard Exec Summary</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item
              v-if="inRole('Yard Spot') || inRole('Dispatch')"
              :to="{ path: '/bays/ADC' }"
              >ADC Bay Board</b-dropdown-item
            >
            <b-dropdown-item
              v-if="inRole('Yard Spot') || inRole('Dispatch')"
              :to="{ path: '/bays/MTJOY' }"
              >MTJOY Bay Board</b-dropdown-item
            >
            <!--
            <b-dropdown-item
              v-if="inRole('Yard Spot') || inRole('Dispatch')"
              :to="{ path: '/bays/MTJOPEN' }"
              >MTJOPEN Bay Board</b-dropdown-item
            >
            -->
            <b-dropdown-divider />
            <b-dropdown-item
              v-if="inRole('Dispatch')"
              :to="{ name: 'driverdispatch' }"
              >Driver Routes</b-dropdown-item
            >
            <b-dropdown-item v-if="inRole('Dispatch')" :to="{ name: 'drivers' }"
              >Drivers</b-dropdown-item
            >
            <b-dropdown-item
              v-if="inRole('Drivers')"
              :to="{ name: 'routesfordriver' }"
              >My Routes</b-dropdown-item
            >
            <b-dropdown-item v-if="inRole('Dispatch')" :to="{ name: 'edi' }"
              >Load Tenders</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item
              v-if="inRole('Dispatch') || inRole('Yard Spot')"
              :to="{ name: 'spot' }"
              >Spot Requests</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            v-show="inRole('Manifest Billing')"
            text="Billing"
          >
            <b-dropdown-item :to="{ name: 'rate' }"
              >Rate Manifests</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'weeklyedi' }"
              >Weekly EDI</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <!--
          <b-nav-item-dropdown
            v-if="inRole('Inventory Management')"
            text="Inventory"
          >
            <b-dropdown-item :to="{ name: 'invinboundmanagement' }"
              >Inbound Management</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'invbundlemanagement' }"
              >Bundle Management</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'invlocationmanagement' }"
              >Location Management</b-dropdown-item
            >
          </b-nav-item-dropdown>
          -->
          <b-nav-item-dropdown v-if="loggedIn" text="Misc">
            <b-dropdown-item
              v-if="
                inRole('Quad City Freight Service, Inc') ||
                inRole('Fork Truck Audit')
              "
              :to="{ name: 'forktruckaudit' }"
              >Fork Truck Audit</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item
              v-if="inRole('JDM Barcoding')"
              :to="{ name: 'jdm' }"
              >JDM Barcoding</b-dropdown-item
            >
            <b-dropdown-item
              v-if="inRole('Quad City Freight Service, Inc')"
              :to="{ name: 'serial' }"
              >Serial Numbers</b-dropdown-item
            >
            <!--
            <b-dropdown-item :to="{ name: 'bundlestorage' }"
              >Lookup Bundle</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'bundlestoragemanagement' }"
              >Manage Bundles</b-dropdown-item
            >
            -->
            <b-dropdown-divider
              v-if="
                inRole('Export Inventory') ||
                inRole('Export Inventory ReadOnly')
              "
            />
            <b-dropdown-item
              v-if="
                inRole('Export Inventory') ||
                inRole('Export Inventory ReadOnly')
              "
              :to="{ name: 'export' }"
              >Export</b-dropdown-item
            >
            <!--
            <b-dropdown-divider />
            <b-dropdown-item :to="{ name: 'vaxuploaddoc' }"
              >Vax Test Upload</b-dropdown-item
            >
            <b-dropdown-item
              v-if="inRole('VaxAdmin')"
              :to="{ name: 'vaxemployees' }"
              >Vax Test Employees</b-dropdown-item
            >
            -->
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            v-show="inRole('Manifest Clerk')"
            text="Manifesting"
          >
            <b-dropdown-item :to="{ name: 'inbshipments' }"
              >Incoming</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item :to="{ name: 'openmanifests' }"
              >Open Manifests</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item :to="{ name: 'openbols' }"
              >Open BOLs</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'bolempty' }">BOL</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item :to="{ name: 'parcels' }">Parcels</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item target="manifestFromMissy" :href="manifestUrl"
              >QCFS Manifest</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-form v-if="inRole('Quad City Freight Service, Inc')">
            <b-nav-text>Default Facility</b-nav-text>
            <b-form-select v-model="facility" size="sm">
              <b-form-select-option
                v-if="facility == null"
                :value="null"
                disabled
                >-- None --</b-form-select-option
              >
              <b-form-select-option value="ADC">ADC</b-form-select-option>
              <b-form-select-option value="MTJOY">MTJOY</b-form-select-option>
              <b-form-select-option value="ATIRI">ATIRI</b-form-select-option>
              <b-form-select-option value="MTJOPEN"
                >MTJOPEN</b-form-select-option
              >
            </b-form-select>
          </b-nav-form>
          <b-nav-item v-show="loggedIn" @click="signOut"
            >Hello, {{ firstName }} | Sign Out</b-nav-item
          >
          <b-nav-item v-show="!loggedIn" @click="signIn">Sign In</b-nav-item>
          <b-nav-item>
            <font-awesome-icon
              icon="circle"
              :class="messagingIndicatorClass"
              :title="messagingIndicatorTitle"
            />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-row
      v-if="notificationMessage != null"
      style="text-align: center"
      :class="notificationMessageClass"
    >
      <b-col>
        {{ notificationMessage }}
      </b-col>
    </b-row>
    <b-container fluid class="d-flex h-100" style="overflow: hidden">
      <!-- overflow:hidden -->
      <router-view></router-view>
    </b-container>
    <b-container fluid>
      <div
        class="row flex-fill card-footer text-muted small font-weight-lighter font-italic"
      >
        Served from {{ cluster }} {{ cluster_node }} at {{ served_at }}
      </div>
    </b-container>
    <FileUploadModal />
    <ShowLogModal />
    <AddTrailerModel />
    <UpdateTrailerStatusModal />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import messaging from "./messaging";
import dateutil from "./dateutil";
import FileUploadModal from "./components/common/FileUploadModal.vue";
import ShowLogModal from "./components/common/ShowLogModal.vue";
import AddTrailerModel from "./components/common/AddTrailerModal.vue";
import UpdateTrailerStatusModal from "./components/common/UpdateTrailerStatusModal.vue";
import { MANIFEST_URL } from "./config";

var _cluster = null;
var _node = null;

try {
  // eslint-disable-next-line no-undef
  _cluster = QCFS_CLUSTER;
  // eslint-disable-next-line no-undef
  _node = QCFS_NODE;
} catch (e) {
  _cluster = "local";
  _node = "localhost";
}

export default {
  components: {
    FileUploadModal,
    ShowLogModal,
    AddTrailerModel,
    UpdateTrailerStatusModal,
  },
  data() {
    return {
      cluster: _cluster.toUpperCase(),
      cluster_node: _node.toUpperCase(),
      served_at: dateutil.getLocalDateTimeNow("YYYY-MM-DD hh:mma z"),
      manifestUrl: MANIFEST_URL,
    };
  },
  methods: {
    ...mapActions("security", ["signIn", "signOut", "refreshSecurity"]),
    ...mapActions("common", [
      "setDefaultFacility",
      "initGlobals",
      "handleGlobalMessage",
      "setMessagingConnected",
    ]),
    doSignOut() {
      this.signOut();
      this.$router.push("/");
    },
    selectDefaultFacility(f) {
      this.$cookies.set("default_facility", f);
      this.setDefaultFacility(f);
    },
    subscribe() {
      messaging.subscribe(
        "App.GlobalMessage",
        "/topic/Topic.GlobalMessage",
        this.handleGlobalMessage,
      );
    },
    unsubscribe() {
      messaging.unsubscribe("App.GlobalMessage");
    },
  },
  computed: {
    ...mapGetters("security", ["loggedIn", "firstName", "inRole"]),
    ...mapGetters("common", [
      "defaultFacility",
      "globalNotificationMessage",
      "messagingConnected",
    ]),
    facility: {
      get: function () {
        return this.defaultFacility;
      },
      set: function (val) {
        this.selectDefaultFacility(val);
      },
    },
    notificationMessage() {
      let nm = null;
      if (this.globalNotificationMessage != null) {
        nm = this.globalNotificationMessage.message;
      }
      return nm;
    },
    notificationMessageClass() {
      let c = null;
      if (this.globalNotificationMessage != null) {
        c = `bg-${this.globalNotificationMessage.level}`;
        if (this.globalNotificationMessage.level != "warning") {
          c = `${c} text-white`;
        }
      }
      return c;
    },
    messagingIndicatorClass() {
      return this.messagingConnected
        ? "messagingConnected"
        : "messagingDisconnected";
    },
    messagingIndicatorTitle() {
      return this.messagingConnected
        ? "Connected to messaging server, real time updates will be received"
        : "Disconnected from messaging server, data on this page will not update in real time";
    },
  },
  created() {
    if (this.$cookies.get("default_facility")) {
      this.setDefaultFacility(this.$cookies.get("default_facility"));
    }
  },
  mounted() {
    this.refreshSecurity();
    messaging.msgClient.onWebSocketError = () => {
      this.setMessagingConnected(false);
    };
    messaging.msgClient.onWebSocketClose = () => {
      this.setMessagingConnected(false);
    };
    messaging.onConnect = () => {
      this.setMessagingConnected(true);
    };
    this.initGlobals();
    this.subscribe();
    messaging.restart();
  },
  destroyed() {
    this.unsubscribe();
  },
};
</script>

<style>
html,
body {
  height: 100%;
}
.fill-height {
  height: 100%;
}
a {
  color: black;
}
.fade-left-enter,
.fade-left-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}
.fade-left-leave-active,
.fade-left-enter-active {
  transition: all 0.5s ease-in;
}
#mainContainer {
  padding: 0px;
}
.redTag {
  color: red;
}
.yellowTag {
  color: gold;
}
.row-striped:nth-of-type(odd) {
  background-color: #efefef;
}
.row-striped:nth-of-type(even) {
  background-color: #ffffff;
}
.btn-wrap {
  background-color: lightgray;
}
.nowrap {
  white-space: nowrap;
}
.clickable {
  color: #007bff;
}
.messagingConnected {
  color: green;
}
.messagingDisconnected {
  color: red;
}
</style>
